import React, { useState } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';
import './SearchComponent.css';
import axiosInstance from '../../common/axiosInstance';
import { openCommentInNewWindow } from '../../common/helper';

const SearchComponent = ({status, placeholder}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term.length > 0) {
      setIsLoading(true);
      setShowDropdown(true);
      try {
        let response;
        if (status === 'token') {
          response = await axiosInstance.get(`/token/${term}`);
          console.log('API response data:', response.data); // For debugging

          if (response.data) {
            setSearchResults([response.data]);
          } else {
            setSearchResults([]);
          }
        } else if (status === "comments") {
          response = await axiosInstance.post(`/club/search`, { url: term });
          console.log('API response data:', response.data); // For debugging

          if (response.data) {
            setSearchResults([response.data]);
          } else {
            setSearchResults([]);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setSearchResults([]);
      } finally {
        setIsLoading(false);
      }
    } else {
      setShowDropdown(false);
      setIsLoading(false);
      setSearchResults([]); // Clear results when input is empty
    }
  };

  const handleSelect = (token) => {
    setSearchTerm(token.ticker);
    setShowDropdown(false);
    openCommentInNewWindow(token.contractAddress);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setSearchResults([]);
    setShowDropdown(false);
  };

  return (
    <div className="search-component">
      <div className="search-input-container">
        <FaSearch className="search-icon" />
        <input
          type="text"
          placeholder={placeholder}
          value={searchTerm}
          onChange={handleSearch}
        />
        {searchTerm && <FaTimes className="clear-icon" onClick={clearSearch} />}
      </div>
      {(showDropdown && (searchResults.length > 0 || !isLoading)) && (
        <div className="dropdown">
          {isLoading ? (
            <div className="loading-circle"></div>
          ) : (
            searchResults.length > 0 ? (
              searchResults.map((token, index) => (
                <div
                  key={index}
                  className="dropdown-item"
                  onClick={() => handleSelect(token)}
                >
                  <img src={token.logo} alt={token.name} />
                  <span>{token.name} {token.ticker ? `| ${token.ticker}` : ""}</span>
                </div>
                
              ))
            ) : (
              <div className="no-results">No records found</div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default SearchComponent;
