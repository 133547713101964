import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { auth, db } from '../config/firebase-config';
import LoadingScreen from '../component/LoadingScreen/LoadingScreen';
import { addUser} from '../common/repository';
import { set } from 'date-fns';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingPostLogin, setLoadingPostLogin] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.role !== 'admin') {
      const q = query(collection(db, 'notifications'), where('ownerUserId', '==', user.id), where('read', '==', false));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        setNotificationCount(snapshot.size);
      });

      return () => unsubscribe();
    }
  }, [user]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setLoading(true);
      if (currentUser) {
        const user = await addUser(currentUser);
        if (user) {
          setUser(user.data);
        }
      } else {
        setUser(null);
        navigate('/login');
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    let unsubscribeFromNotifications = () => {};
  
    if (user && user.role !== 'admin') {
      const q = query(collection(db, 'notifications'), where('ownerUserId', '==', user.id));
  
      unsubscribeFromNotifications = onSnapshot(q, (snapshot) => {
        const allNotifications = [];
        const unreadNotifications = [];
  
        snapshot.forEach((doc) => {
          const notification = { id: doc.id, ...doc.data() };
          allNotifications.push(notification);
          if (!notification.read) {
            unreadNotifications.push(notification);
          }
        });
  
        setNotifications(allNotifications);
        setNotificationCount(unreadNotifications.length);
      });
    }
  
    return () => {
      unsubscribeFromNotifications();
    };
  }, [user]);

  const signInWithGoogle = () => {
    setLoadingPostLogin(true);
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).then(async (result) => {
      // const currentUser = result.user;

      // if (user) {
      //   setUser(user.data);
      //   navigate('/home');
      // }

      setLoadingPostLogin(false);
    }).catch((error) => {
      console.error("Error during sign-in:", error);
      setLoadingPostLogin(false);
    });
  };

  const logout = () => {
    signOut(auth);
    setUser(null);
    navigate('/login');
  };

  const value = { user, loading, signInWithGoogle, logout, setNotifications, notifications, loadingPostLogin,notificationCount };

  return (
    <AuthContext.Provider value={value}>
      {loading || loadingPostLogin ? <LoadingScreen /> : children}
    </AuthContext.Provider>
  );
};
