import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import Comments from '../comment/Comments';
import './ThreadWindow.css';
import { useSearchParams } from 'react-router-dom';
import { deleteCommentById, handlePostComment } from '../../common/repository';
import FilterDropDown from '../filter/FilterDropDown';
import TextBoxWithSend from '../Textbox/TextBoxWithSend';
import Modal from 'react-modal';
import axiosInstance from '../../common/axiosInstance';
import socket from '../../context/socket';
import { BeatLoader } from 'react-spinners';
import DeleteModal from '../DeleteModal/DeleteModal';
import Follow from '../follow/Follow';
import ClubModal from '../ClubModal/ClubModal';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase-config';
import { FaExclamationTriangle } from 'react-icons/fa';

function ThreadWindow() {
  const [searchParams] = useSearchParams();
  const url = searchParams.get('url');
  const [actualUrl, setActualUrl] = useState(url);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [activeCommentId, setActiveCommentId] = useState(null);
  const { user } = useAuth();
  const [filterType, setFilterType] = useState('mixed');
  const [selectedCommentId, setSelectedCommentId] = useState(null);
  const [reportedUserId, setReportedUserId] = useState(null);
  const [spamReason, setSpamReason] = useState('');
  const [loadingComments, setLoadingComments] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSpamModalOpen, setIsSpamModalOpen] = useState(false);
  const [currentUserFollow, setCurrentUserFollow] = useState(false);
  const [setDays, setSetDays] = useState(null);
  const [canComment, setCanComment] = useState(true);
  const [nameDetails, setNameDetails] = useState(null); 
  const [isUrl, setIsUrl] = useState(false);
  const [websiteUlogo, setWebsiteUlogo] = useState(null); 
  const [ticker, setticker] = useState(null); 


  useEffect(() => {
    checkStatusForFollow();
    checkCommentPermission();
  }, [user.id]);


  useEffect(() => {
    if (url) {
      if (url.startsWith('http')) {
        // It's a URL
        setIsUrl(true);
        
        // Extract the hostname and get the main domain part
        const hostname = new URL(url).hostname;
        const parts = hostname.split('.').filter(part => part !== 'www');
        const domainName = parts.length > 1 ? parts[0] : hostname;

        setNameDetails({
          name: domainName,
          initial: domainName.charAt(0).toUpperCase(),
        });
      } else {
        fetchTokenDetails(url);
      }
    }
  }, [url]);


  const fetchTokenDetails = async (contractAddress) => {
    try {
      const response = await axiosInstance.get(`/token/${contractAddress}`);
      if (response.data) {
        setNameDetails({
          name: response.data.name,
          ticker: response.data.ticker,
          logo: response.data.logo,
        });
        setWebsiteUlogo(response.data.logo);
        setticker(response.data.name + " | " + response.data.ticker);
      } else {
        setNameDetails(null);
      }
    } catch (error) {
      console.error('Error fetching token details:', error);
    }
  };


  const checkStatusForFollow = async () => {
    try {
      const response = await axiosInstance.post('/follow/getStatus', {
        userId: user.id,
        url: url,
      });

      if (response.status === 200) {
        setCurrentUserFollow(response.data);
      } else {
        setCurrentUserFollow(false);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const checkCommentPermission = async () => {
    const result = await canUserComment(user.id);
    setCanComment(result);
  };

  const canUserComment = async (userId) => {
    try {
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const { commentBanUntil } = userDoc.data();
        if (commentBanUntil && Date.now() < commentBanUntil) {
          const banHoursLeft = Math.ceil((commentBanUntil - Date.now()) / (60 * 60 * 1000));
          // alert(`You cannot comment for the next ${banHoursLeft} hours due to repeated spam reports.`);
          setSetDays(banHoursLeft);
          return false;
        }
      }
      return true;
    } catch (error) {
      console.error('Error checking comment permissions: ', error);
      return true;
    }
  };

  useEffect(() => {
    socket.on('commentPosted', newComment => {
      setComments(prevComments => {
        if (newComment.parentId) {
          return prevComments.map(comment => {
            if (comment.id === newComment.parentId) {
              return {
                ...comment,
                replies: [...comment.replies, newComment]
              };
            }
            return comment;
          });
        } else {
          return [...prevComments, newComment];
        }
      });
    });

    socket.on('likeDislikeUpdated', data => {
      setComments(prevComments => {
        return prevComments.map(comment => {
          if (comment.id === data.commentId) {
            return { ...comment, ...data.result };
          }
          if (comment.replies) {
            return {
              ...comment,
              replies: comment.replies.map(reply => {
                if (reply.id === data.commentId) {
                  return { ...reply, ...data.result };
                }
                return reply;
              })
            };
          }
          return comment;
        });
      });
    });

    return () => {
      socket.off('commentPosted');
      socket.off('likeDislikeUpdated');
    };
  }, []);

  useEffect(() => {
    if (url) {
      fetchComments(url);
    } else {
      setLoadingComments(false);
    }
  }, [url]);

  const fetchComments = async (url) => {
    try {
      const response = await axiosInstance.post('/filter', { url, type: 'mixed' });
      setComments(response.data);
      setLoadingComments(false);
    } catch (error) {
      console.error('Error fetching comments:', error);
      setLoadingComments(false);
    } finally {
      setLoadingComments(false);
    }
  };

  const handleLike = useCallback((commentId, parentId = null) => {
    const updateCommentsLocally = (updateFn) => {
      setComments(prevComments =>
        prevComments.map(comment => {
          if (comment.id === commentId) {
            return updateFn(comment);
          }
          if (parentId && comment.id === parentId) {
            return {
              ...comment,
              replies: comment.replies.map(reply =>
                reply.id === commentId ? updateFn(reply) : reply
              )
            };
          }
          return comment;
        })
      );
    };

    const comment = comments.find(c => c.id === commentId || (c.replies && c.replies.find(r => r.id === commentId)));
    const isReply = parentId !== null;
    const targetComment = isReply ? comment.replies.find(r => r.id === commentId) : comment;
    const userLiked = targetComment.likedBy.includes(user.id);

    updateCommentsLocally(comment => {
      const likedBy = userLiked
        ? comment.likedBy.filter(uid => uid !== user.id)
        : [...comment.likedBy, user.id];
      const likes = userLiked ? comment.likes - 1 : comment.likes + 1;

      return {
        ...comment,
        likedBy,
        likes,
      };
    });

    axiosInstance
      .post('/comments/like', {
        commentId,
        uid: user.id,
      })
      .catch(error => {
        console.error('Error liking comment:', error);
      });
  }, [user.id, comments, setComments]);

  const handleDislike = useCallback((commentId, parentId = null) => {
    const updateCommentsLocally = (updateFn) => {
      setComments(prevComments =>
        prevComments.map(comment => {
          if (comment.id === commentId) {
            return updateFn(comment);
          }
          if (parentId && comment.id === parentId) {
            return {
              ...comment,
              replies: comment.replies.map(reply =>
                reply.id === commentId ? updateFn(reply) : reply
              )
            };
          }
          return comment;
        })
      );
    };

    const comment = comments.find(c => c.id === commentId || (c.replies && c.replies.find(r => r.id === commentId)));
    const isReply = parentId !== null;
    const targetComment = isReply ? comment.replies.find(r => r.id === commentId) : comment;
    const userDisliked = targetComment.dislikedBy.includes(user.id);

    updateCommentsLocally(comment => {
      const dislikedBy = userDisliked
        ? comment.dislikedBy.filter(uid => uid !== user.id)
        : [...comment.dislikedBy, user.id];
      const dislikes = userDisliked ? comment.dislikes - 1 : comment.dislikes + 1;

      return {
        ...comment,
        dislikedBy,
        dislikes,
      };
    });

    axiosInstance
      .post('/comments/dislike', {
        commentId,
        uid: user.id,
      })
      .catch(error => {
        console.error('Error disliking comment:', error);
      });
  }, [user.id, comments, setComments]);

  const handleSend = useCallback(async () => {
    if (!newComment.trim()) return;

    const commentData = {
      author: user.name || 'Anonymous',
      content: newComment,
      likes: 0,
      dislikes: 0,
      parentId: activeCommentId || null,
      userId: user.id,
      webpageUrl: actualUrl,
      email: user.email,
      createdAt: new Date(),
      profile: user.profilePicture,
      likedBy: [],
      dislikedBy: [],
      username: user.username,
      replies: [],
      websiteLogo : websiteUlogo,
      ticker : ticker
    };

    try {
      await handlePostComment(commentData);
      setNewComment('');
      setActiveCommentId(null);
      fetchComments(actualUrl);  // Refresh comments
    } catch (error) {
      console.error('Error posting comment:', error);
    }
  }, [newComment, activeCommentId, user, actualUrl]);

  const handleDeleteComment = useCallback(async () => {
    try {
      await deleteCommentById(selectedCommentId);
      fetchComments(actualUrl);  // Refresh comments
      setSelectedCommentId(null);
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  }, [selectedCommentId, actualUrl]);

  const handleReportSpam = useCallback(async () => {
    try {
      await axiosInstance.post('/comments/reportSpam', { commentId: selectedCommentId, uid: user.id, name: user.name, reason: spamReason, reportedUserId: reportedUserId });
      setSelectedCommentId(null);
      setSpamReason('');
      setIsSpamModalOpen(false);
    } catch (error) {
      console.error('Error reporting spam:', error);
    }
  }, [selectedCommentId, spamReason, user.id]);

  const handleFilterChange = async (filter) => {
    setLoadingComments(true); // Set loading state to true before fetching comments
    try {
      const response = await axiosInstance.post('/filter', { url: actualUrl, type: filter });
      setComments(response.data);
      setFilterType(filter);
    } catch (error) {
      console.error('Error fetching filtered comments:', error);
      setLoadingComments(false);
    } finally {
      setLoadingComments(false); 
    }
  };

  const openDeleteModal = (commentId) => {
    setSelectedCommentId(commentId);
    setIsDeleteModalOpen(true);
  };

  const openSpamModal = (comment) => {
    setSelectedCommentId(comment.id);
    setReportedUserId(comment.userId);
    setIsSpamModalOpen(true);
  };

  return (
    <div className="chat-window">
      <div className='thread-title-container'>
        {nameDetails ? (
          isUrl ? (
            <div className="url-display">
              <div className="url-avatar" style={{ backgroundColor: 'white', color: 'orange' }}>
                {nameDetails.initial}
              </div>
              <div className="url-details">
                <h4>{nameDetails.name}</h4>
                <p className='address-title-url'>{url}</p>
              </div>
            </div>
          ) : (
            <div className="token-display">
              <img src={nameDetails.logo} alt={nameDetails.name} className="token-logo" />
              <div className="token-details">
                <h5>{nameDetails.name} | {nameDetails.ticker}</h5>
                <p className='address-title-url'>{url}</p>
              </div>
            </div>
          )
        ) : (
          <h4>{actualUrl}</h4>
        )}


        <div className='follow-filter-container'>
          {
            user && user.role === 'user' ? (
              <Follow url={actualUrl} initialFollowStatus={currentUserFollow} userId={user.id} />
            ) : (
              <ClubModal url={actualUrl} />
            )
          }
          <FilterDropDown onSelectFilter={handleFilterChange} filterType={filterType} />
        </div>

      </div>

      <div className="message-list">
        {loadingComments ? (
          <div className='loading-spinner'><BeatLoader color="#FEAF04" size={20} /></div>
        ) : (
          comments.length > 0 &&
          comments.map(comment => (
            <Comments
              key={comment.id}
              comment={comment}
              setActiveCommentId={setActiveCommentId}
              onLike={(id, parentId) => handleLike(id, parentId)}
              onDislike={(id, parentId) => handleDislike(id, parentId)}
              onDeleteClick={openDeleteModal}
              onReportSpamClick={openSpamModal}
            />
          ))
        )}
      </div>

      {canComment ? (
        <div className="message-input">
          <TextBoxWithSend
            newComment={newComment}
            setNewComment={setNewComment}
            handleSend={handleSend}
            activeCommentId={activeCommentId}
            setActiveCommentId={setActiveCommentId}
          />
        </div>
      ) : (
        <div className="attention-message">
  <FaExclamationTriangle size={24} style={{ marginRight: '12px' }} />
  You cannot comment for the next {setDays} hours due to repeated spam reports.
</div>
      
      )}

      {/* Delete Confirmation Modal */}
      <DeleteModal isDeleteModalOpen={isDeleteModalOpen} setIsDeleteModalOpen={setIsDeleteModalOpen} action={handleDeleteComment} />

      <Modal
        isOpen={isSpamModalOpen}
        onRequestClose={() => setIsSpamModalOpen(false)}
        className="modal"
        overlayClassName="overlay"
      >
        <div className="modal-header">
          <h2>Report Spam</h2>
          <button onClick={() => setIsSpamModalOpen(false)}>&times;</button>
        </div>
        <div className="modal-content">
          <p>Are you sure you want to report this comment as spam?</p>
          <textarea
            placeholder="Optional reason"
            value={spamReason}
            onChange={(e) => setSpamReason(e.target.value)}
          />
          <div className="modal-actions">
            <button className="save-button" onClick={handleReportSpam}>Report</button>
            <button className="cancel-button" onClick={() => setIsSpamModalOpen(false)}>Cancel</button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ThreadWindow;
