import React, { useCallback, useState } from 'react';
import Modal from 'react-modal';
import axiosInstance from '../../common/axiosInstance';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ClubModal.css';

function ClubModal({ url }) {
    const [isSpamModalOpen, setIsSpamModalOpen] = useState(false);
    const [addUrl, setAddUrl] = useState('');

    const openSpamModal = (club) => {
        setIsSpamModalOpen(true);
        setAddUrl(club);
    };

    const handleClubUrl = async () => {
        try {
            console.log("Adding URL:", addUrl);
            
            const response = await axiosInstance.post('/club/add', {
                source: url,
                destination: addUrl
            });

            // Check for a successful response
            if (response.status === 200) {
                toast.success('URLs clubbed successfully!');
                setAddUrl('');
                setIsSpamModalOpen(false);
            } else {
                toast.error('Failed to club URLs.');
                setIsSpamModalOpen(false);

            }
        } catch (error) {
            console.error('Error clubbing URLs:', error);
            toast.error('An error occurred while clubbing URLs.');
            setIsSpamModalOpen(false);

        }
    };

    return (
        <div>
            <button className="club-button" onClick={() => openSpamModal("")}>
                Club
            </button>
            <Modal
                isOpen={isSpamModalOpen}
                onRequestClose={() => setIsSpamModalOpen(false)}
                className="modal"
                overlayClassName="overlay"
            >
                <div className="modal-header">
                    <h2>Club Threads</h2>
                    <button onClick={() => setIsSpamModalOpen(false)}>&times;</button>
                </div>
                <div className="modal-content">
                    <input
                        className='add-url-input'
                        type='text'
                        placeholder="https://www.example.com/"
                        value={addUrl}
                        onChange={(e) => setAddUrl(e.target.value)}
                    />
                    <div className="modal-actions">
                        <button
                            className="save-button"
                            onClick={handleClubUrl}
                            disabled={!addUrl.trim()}  // Disable the button if input is empty
                        >
                            Add
                        </button>
                        <button className="cancel-button" onClick={() => setIsSpamModalOpen(false)}>Cancel</button>
                    </div>
                </div>
            </Modal>
            <ToastContainer />
        </div>
    );
}

export default ClubModal;
